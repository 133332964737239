
.spare-parts{
    display: flex;
    flex-direction: column;
    height: 100%;
    .addSpare-info {
        padding: 0 20px;
        /*height: calc(100vh - 220px);*/
        .addSpare-title {
            font-size: 20px;
            padding-left:16px;
            line-height: 1;
            margin: 20px 0 30px;
            display: flex;
            align-items: center;
        }
    }
    .dialog-footer {
        text-align: center;
        margin: 20px 0;
    }
    .where-address{
        &:before{
            content: "*";
            position: absolute;
            color: #E84932;
            left: 32px;
            margin-top: 12px;
        }
    }
    ::v-deep .el-form{
        height: 100%;
    }
}

